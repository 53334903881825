

































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import {Component, Mixins, Watch} from 'vue-property-decorator';
import {API} from "@/services/api-provider"
import Utils from "@/mixins/utils";
import ReadOnlyField from "@/components/ReadOnlyField.vue";
import {CompanySearchQuery, CompanySource} from "@/models/CompanySearchQuery";
import {RawCompanyRecordResponse} from "@/models/raw-company-record-response";
import {CompanySearchResponse} from "@/models/company-search-response";
import {ScbCompany} from "@/models/raw-company-record";
import {Route} from "vue-router";
import Foldable from "@/components/Foldable.vue";
import EditableColumnOfFields, {
    createField,
    Field
} from "@/components/EditableColumnOfFields.vue";

type UrlParams = {
    searchType: 'raw' | 'companyCard';
    orgNo: string
}

type UrlQuery = {
    useDbVersion: "true" | "false";
    sources: CompanySource[];
    currentDate: string;
}

type QueryState = {
    orgNo: string;
    useDbVersion: boolean;
    currentDate?: string;
    // eslint-disable-next-line no-unused-vars
    sources: Set<CompanySource>;
}

@Component({components: {EditableColumnOfFields, Foldable, ReadOnlyField}})
export default class Company extends Mixins(Utils) {

    private searching: boolean = false;
    private badSearch: boolean = false;
    private errorMessage: string = "";
    private query: QueryState = Company.defualtQueryState();

    private checkboxSourceChanged(e: {
        target: { value: CompanySource; checked: boolean };
    }) {
        if (e.target.checked) {
            this.query.sources.add(e.target.value);
        } else {
            this.query.sources.delete(e.target.value);
        }
    }

    private articlesOfAssociationMappings: any = {
        "OTHER_RESERVATIONS_OR_DISCREPANCIES": "Övriga förbehåll eller avvikelser",
        "DISCREPANCY_CONCERNING_RIGHT_TO_VOTE": "Avvikelse avseende rösträtt",
        "RESERVATION_OFFER_OF_FIRST_REFUSAL": "Hembudsförbehåll",
        "RESERVATION_DISPUTES_SETTLEMENT_THROUGH_ARBITRATORS": "Förbehåll om tvisters avgörande av skiljemän",
        "RESERVATION_VOTING_MAJORITY": "Förbehåll om röstmajoritet",
        "RECORD_DAY_PROVISTION": "Avstämningsförbehåll",
        "RIGHT_OF_PRIORITY": "Företrädesrätt",
        "CONDITIONS_PRECEDENT": "Förköpsförbehåll",
        "RESERVATION_CONCERING_CONSENT": "Samtyckesförbehåll",
        "DOES_NOT_REQUIRE_ACCOUNTANT": "Företaget behöver inte ha revisor",
        "RESERVATION_TRANSFORMATION": "Omvandlingsförbehåll",
        "RESERVATION_REDEMPTION": "Inlösenförbehåll",
        "PROVISION_LIQUIDATION": "Likvidationsbestämmelse",
        "RESERVATION_PUBLIC_BUYOUT_OFFER": "Förbehåll om offentligt uppköpserbjudande"
    }

    private refData: any = false;
    private response: false | RawCompanyRecordResponse | CompanySearchResponse = false;
    private postTypes: any = false;
    private recordDefinitions: any = false;
    private fieldDescriptions: any = false;

    setStoreState() {
        this.$store.dispatch("setCurrentState", "COMPANY-SEARCH")
    }

    /**
     * Gets data that the component need to render properly
     */
    getBaseData() {
        API.getCompanyRefData().then((response: any) => {
            this.recordDefinitions = response.recordDefinitions;
            this.postTypes = response.postTypes;
            this.refData = response;
        });
        API.getCompanyFields().then((response: any) => {
            this.fieldDescriptions = response.fields;
        });
    }

    getRVHBaseFields(): Field[] {
        if (typeof this.response != "boolean" && "companyRawRecord" in this.response && this.response.companyRawRecord.beneficialRecord) {
            const rawRecord = this.response.companyRawRecord;
            return this.refData.beneficialRecordFields.map((fieldName: string) => {
                return createField(fieldName, rawRecord.beneficialRecord[fieldName])
            });
        }
        return [];
    }

    getRVHReportingObligationFields(): Field[] {
        if (typeof this.response != "boolean" && "companyRawRecord" in this.response) {
            const rawRecord = this.response.companyRawRecord;
            return this.refData.rvhReportingObligationFields.map((fieldName: string) => {
                return createField(fieldName, rawRecord.rvhReportingObligation[fieldName])
            });
        }
        return [];
    }

    doSearch(raw: boolean): Promise<void> {
        if (this.searching) {
            return new Promise<void>(() => console.log("already searching"));
        }
        return new Promise<void>(resolve => {
            this.badSearch = false;
            this.searching = true;
            const searchQuery = Company.componentQueryToSearchQuery(this.query);
            let responsePromise: Promise<RawCompanyRecordResponse | CompanySearchResponse>;
            if (raw) {
                responsePromise = API.searchCompanyRaw(searchQuery);
            } else {
                responsePromise = API.searchCompany(searchQuery);
            }
            responsePromise.then((data: RawCompanyRecordResponse | CompanySearchResponse) => {
                if ("companyRawRecord" in data && data.companyRawRecord.empty ||
                    "company" in data && data.company.empty) {
                    this.errorMessage = "Ingen sökträff.";
                    this.badSearch = true;
                } else {
                    this.response = data;
                }
            }).catch((resp: any) => {
                this.errorMessage = this.extractErrorMessage(resp);
                console.log(this.errorMessage);
                this.badSearch = true;
            }).finally(() => {
                this.searching = false;
                resolve();
            });
        });
    }

    search(): void {
        this.navigateSearch(false);
    }

    /**
     * Constructs the search url from this.query an navigates to it
     *
     * @param raw
     */
    navigateSearch(raw: boolean) {
        let params: UrlParams = {
            searchType: raw ? "raw" : "companyCard",
            orgNo: this.query.orgNo
        }

        let query: UrlQuery | Omit<UrlQuery, "currentDate"> = {
            // eslint-disable-next-line no-unused-vars
            sources: Array.from(this.query.sources.values()),
            useDbVersion: this.query.useDbVersion ? 'true' : 'false'
        }

        if (this.query.currentDate)
            query = {...query, currentDate: this.query.currentDate}

        const currRoute = this.$route;

        this.$router.push({
            name: 'company',
            params,
            query
        })

        const newRoute = this.$route;
        // Force new search if the route did not change.
        if (currRoute == newRoute) {
            this.onRouterChange(currRoute);
        }

    }


    searchRaw(): void {
        this.navigateSearch(true);
    }

    getStatusDescription(status: string): string {
        if (!status || !this.refData) {
            return '';
        }
        return status + ' - ' + this.refData.companyStatusTexts[status];
    }

    get sortedSCBCompanies(): ScbCompany[] {
        //<div v-for="(scbCompany, idx) in response.companyRawRecord.scbCompanies"
        if (typeof this.response !== 'boolean' && 'companyRawRecord' in this.response) {
            return [...this.response.companyRawRecord.scbCompanies].sort((a, b) => {
                if (a.superseded == null || a.superseded > b.superseded) {
                    return -1; // a before b
                } else if (b.superseded == null || a.superseded < b.superseded) {
                    return 1;
                } else {
                    return 0;
                }
            });
        } else {
            return [];
        }
    }

    getBusinessName(business: any): string {
        if (!business || business.names.length < 1) {
            return '';
        }
        // Need to copy array to prevent infinite rendering loops.
        const business_names_sorted = [...business.names].sort(function (a: any, b: any) {
            if (a.date < b.date)
                return 1;
            if (a.date > b.date)
                return -1;
            return 0;
        });
        return business_names_sorted [0].name;
    }

    /**
     * Restore the this.query state from the url and then perform search
     *
     * @param toRoute
     */
    @Watch("$route", {immediate: true, deep: true})
    onRouterChange(toRoute: Route) {
        this.getBaseData();
        this.setStoreState();
        const params = toRoute.params as UrlParams | undefined;
        const query = toRoute.query as Partial<UrlQuery> | undefined;
        this.query = Company.urlToQueryState(params, query);
        if (params && params.searchType) {
            if (params.searchType == "raw") {
                this.doSearch(true);
            } else if (params.searchType == "companyCard") {
                this.doSearch(false);
            }
        }

    }

    getArticlesOfAssociationText(articles: string[]): string {
        if (!articles || articles.length < 1) {
            return '';
        }
        let retArr: string[] = [];
        for (let i = 0; i < articles.length; i++) {
            retArr.push(this.articlesOfAssociationMappings[articles[i]]);
        }
        return retArr.join(", ");
    }

    private static componentQueryToSearchQuery(query: QueryState): CompanySearchQuery {
        return {
            orgNo: query.orgNo,
            useDbVersion: query.useDbVersion,
            currentDate: query.currentDate,
            sources: Array.from(query.sources.values())
        }
    }

    private static defualtQueryState(): QueryState {
        return {
            orgNo: "",
            sources: new Set(["BOLAGSVERKET", "RVH", "SCB", "SKATTEVERKET_MAF", "SYNA", "UC_FINANCIAL"]),
            useDbVersion: true,
        }
    }

    private static urlToQueryState(urlParams?: Partial<UrlParams>, urlQuery?: Partial<UrlQuery>): QueryState {
        let componentQuery: QueryState = this.defualtQueryState();
        if (urlQuery) {
            if (urlQuery.useDbVersion) {
                componentQuery.useDbVersion = urlQuery.useDbVersion === "true";
            }
            if (urlQuery.sources) {
                componentQuery.sources = new Set(urlQuery.sources);
            }
            if (urlQuery.currentDate) {
                componentQuery.currentDate = urlQuery.currentDate;
            }
        }
        if (urlParams) {
            if (urlParams.orgNo) {
                componentQuery.orgNo = urlParams.orgNo;
            }
        }
        return componentQuery;
    }

    mainCompanyFields(response: CompanySearchResponse): Field[] {
        return [
            createField("Relaterat orgnr", response.company.relatedOrgNo),
            createField("Form Legacy", response.company.form),
            createField("Form SCB", response.company.formS),
            createField("Besöksadress", response.company.visitingStreetAddress),
            createField("Besökspostnummer", response.company.visitingZip),
            createField("Besökspostort", response.company.visitingCity),
            createField("Telefonnummer", response.company.phoneNumber),
            createField("Bolaget bildat", response.company.companyCreationDate),
            createField("Bolaget avregistrerat", response.company.deregistrationDate),
            createField("Bolagsordning", response.company.articlesOfAssociation),
            createField("Datum bolagsordning", response.company.articlesOfAssociationDate),
            createField("Lagerbolag", response.company.stockCompany),
            createField("Momsregistrerad", response.company.vatReg),
            createField("Arbetsgivarregistrerad", response.company.socSecContrib),
            createField("F-skatt", response.company.fTaxCertificate),
            createField("SNI-kod huvudbransch", response.company.lineOfBusinessCode1),
            createField("SNI-kod 2", response.company.lineOfBusinessCode2),
            createField("SNI-kod 3", response.company.lineOfBusinessCode3),
            createField("SNI-kod 4", response.company.lineOfBusinessCode4),
            createField("SNI-kod 5", response.company.lineOfBusinessCode5),
            createField("Huvudman - status", response.company.beneficialStatus),
            createField("Misstanke om felaktighet huvudmän/representanter", response.company.beneficialPersonSuspicionLevel),
            createField("Registreringsdatum huvudmän/representanter", new Date(response.company.beneficialRegistrationTime)),
            createField("RVH anmälningsplikt", this.getReportingObligationStatus(response.company.beneficialReportingObligation)),
            createField("Inlagd i systemet", new Date(response.company.created)),
            createField("Källor", response.company.sources),
        ]
    }

    handleReturnPress(): void {
        if (typeof this.response !== "boolean" && "companyRawRecord" in this.response) {
            this.searchRaw();
        } else {
            this.search();
        }
    }

    private getReportingObligationStatus(ro: any) {
        if (ro.status === "UNKNOWN") {
            return "Ingen info";
        } else {
            return ro.status == "NO" ? "Ingen anmälningsplikt sedan " + ro.date : "Åter anmälningspliktigt sedan " + ro.date;
        }
    }

}
