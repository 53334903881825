













































import { Component,Mixins } from 'vue-property-decorator';
//import {HTTP} from "@/services/http"
import {API} from "@/services/api-provider"
import Utils from "@/mixins/utils";
import ReadOnlyField from "@/components/ReadOnlyField.vue";
import {
    defaultMockDefinition,
    FrontendDeliveryDefinition,
    isNotMock,
    MockFrontendDeliveryDefinition
} from "@/models/frontend-delivery-definition";
import DeliveryDefinition from "@/components/DeliveryDefinition.vue";
import Foldable from "@/components/Foldable.vue";
import {
    CreateDeliveryDefinitionRequest,
    UpdateDeliveryDefinitionRequest
} from "@/models/create-delivery-definition-request";
import {
    CreateExportFilterRequest,
    ExportFilter,
    MockExportFilter,
    mockExportFilter
} from "@/models/frontend-export-filter";
import ExportFilterComponent from "@/components/ExportFilterComponent.vue";

@Component({components: {ExportFilterComponent, Foldable, DeliveryDefinition, ReadOnlyField}})
export default class Admin extends Mixins(Utils) {
    private searching: boolean = false;    
    
    private badSearch: boolean = false;
    
    private errorMessage: string = "";
    
    private deliveryDefinitions: (FrontendDeliveryDefinition | MockFrontendDeliveryDefinition)[] = [];

    private newDeliveryDefinitionName: string = "";

    private exportFilters: (ExportFilter | MockExportFilter)[] = [];
    

    beforeCreate() {
        this.$store.dispatch("setCurrentState","ADMIN")    
    }
    
    getDeliveryDefinitions() {
        this.badSearch = false;
        this.searching = true;

        let filterCall = API.getExportFilters().then(data => {
            this.exportFilters = data;
        });

        let delivCall = API.getDeliveryDefinitions().then((data: FrontendDeliveryDefinition[]) => {
            this.deliveryDefinitions = data;
        });

        Promise.all([filterCall, delivCall])
            .catch((resp) => {
                this.errorMessage = this.extractErrorMessage(resp);
                console.log(this.errorMessage);
                this.badSearch = true;
            })
            .finally(
            () => {this.searching = false;}
        )
    }

    createLocalDefinition() {
        if (this.newDeliveryDefinitionName !== "") {
            let mock = defaultMockDefinition();
            mock.name = this.newDeliveryDefinitionName;
            this.newDeliveryDefinitionName = "";
            this.deliveryDefinitions.push(mock)
        }
    }

    createLocalExportFilter() {
        const mock = mockExportFilter();
        this.exportFilters.push(mock);
    }
    
    mounted() {
        this.getDeliveryDefinitions();
    }

    removeDeliveryDef(idx: number) {
        const delivDef: FrontendDeliveryDefinition | MockFrontendDeliveryDefinition = this.deliveryDefinitions[idx];
        if (isNotMock(delivDef)) {
            const do_delete = confirm("Are you sure you permanently want to delete delivery definition with id " + delivDef.id + "?");
            if (do_delete)
                API.deleteDeliveryDefinition(delivDef.id)
                    .then(this.getDeliveryDefinitions);
        } else {
            this.deliveryDefinitions.splice(idx, 1);
        }
    }

    createDeliveryDef(deliveryDefinition: CreateDeliveryDefinitionRequest) {
        API.createDeliveryDefinition(deliveryDefinition)
            .then(this.getDeliveryDefinitions)
            .catch(this.logError);
    }

    updateDeliveryDef(deliveryDef: UpdateDeliveryDefinitionRequest) {
        API.updateDeliveryDefinition(deliveryDef.id, deliveryDef)
            .then(this.getDeliveryDefinitions)
            .catch(this.logError)
    }

    createExportFilter(exportFilter: CreateExportFilterRequest) {
        API.createExportFilter(exportFilter)
            .then(this.getDeliveryDefinitions)
            .catch(this.logError);
    }

    updateExportFilter(event: {id: number, exportFilter: CreateExportFilterRequest}) {
        API.updateExportFilter(event.id, event.exportFilter)
            .then(this.getDeliveryDefinitions)
            .catch(this.logError);
    }


    deleteExportFilter(index: number) {
        const exportFiler = this.exportFilters[index];
        if ("id" in exportFiler) {
            const do_delete = confirm("Are you sure you permanently want to delete export filter with id " + exportFiler.id + "?");
            if (do_delete)
              API.deleteExportFilter(exportFiler.id)
                  .then(this.getDeliveryDefinitions)
                  .catch(this.logError);
        } else {
            this.exportFilters.splice(index, 1);
        }
    }

    getExportFilterName(filter: ExportFilter | MockExportFilter) {
        if ("id" in filter) {
            return "Exportfilter " + filter.id;
        } else {
            return "Exportfilter lokalt";
        }
    }

    logError(error: any) {
        if ("message" in error) {
            alert(error.message);
        }
    }

}
