














































import Vue from "vue";
import {Component, Emit, Prop} from "vue-property-decorator";
import ReadOnlyField from "@/components/ReadOnlyField.vue";
import EditableWrapRow from "@/components/EditableWrapRow.vue";
import PhoneLinkField from "@/components/PhoneLinkField.vue";

export type ValueType = string | boolean | number | string[] | Date | undefined | null;
export type RenderOption = "checkbox" | "phone" | "hideRow" | "custom";
/**
 * Rendering depending on ValueType:
 * ValueType is string without alternatives gives text field
 * ValueType is string with alternatives gives drop down
 * ValueType is boolean gives checkbox if checkbox is true.
 * ValueType is number gives text field
 * ValueType is string[] gives editable freely editable fields.
 * ValueType is string[] with alternatives gives option add and remove the values given in alternatives.
 */
export type Field = { name: string, value: ValueType, renderOption?: RenderOption, alternatives?: string[], editable: boolean }

export function createField(name: string, value: ValueType, editable: boolean = true, renderOption?: RenderOption, alternatives?: string[]): Field {
    return {
        name,
        value,
        editable,
        renderOption,
        alternatives
    }
}

/**
 * List of columns that can either be read only or editable. See {@link Field} for types of fields that can be rendered.
 */
@Component({components: {PhoneLinkField, EditableWrapRow, ReadOnlyField}})
export default class EditableColumnOfFields extends Vue {

    /**
     * Field columns to display
     */
    @Prop()
    value: Field[];

    /**
     * If the fields should be editable. This field is not reactive so it is not possible to toggle between editable
     * and not without rerendering this component.
     */
    @Prop({required: false, default: null})
    editable: boolean | null;

    editable_: boolean = false;

    fieldData: Field[] = [];

    get fields(): Field[] {
        if (this.editable_) {
            return this.fieldData;
        } else {
            return this.value;
        }
    }

    formatFieldValue(value: ValueType): string {
        if (value instanceof Date) {
            return this.formatDateToString(value);
        } else {
            if (value != undefined) {
                return value.toString();
            } else {
                return "";
            }
        }
    }

    formatDateToString(d: Date): string {
        const pad = (x: number) => x.toString().padStart(2, '0');
        return d.getFullYear() + '-' + pad(d.getMonth() + 1) + '-' + pad(d.getDate()) + ' ' + pad(d.getHours()) + ':' + pad(d.getMinutes()) + ':' + pad(d.getSeconds());
    }

    created() {
        if (!this.value) {
            throw new TypeError("value may not be null or undefined");
        }
        this.fieldData = JSON.parse(JSON.stringify(this.value))
        this.emitFieldData();

        if (this.editable == null) {
            // We make the prop editable if the parent has assigned any listeners. "listeners.input" here have to match the
            // name of the emit
            this.editable_ = !!this.$listeners.input;
        } else {
            this.editable_ = this.editable;
        }
    }

    @Emit("input")
    emitFieldData(): Field[] {
        return this.getFieldData();
    }

    /**
     * Used for parents to explicitly get data if they do now want to react to events.
     */
    getFieldData(): Field[] {
        return this.fieldData;
    }

    /**
     * Provides a default value for the select option field. Will choose the the field value if it is port of the
     * alternatives. If it is not it will choose the first element of the alternatives. If none of those options are
     * possible it will return the empty string.
     */
    selectDefaultValue(field: Field): string {
        if (field.alternatives) {
            if (field.value && field.alternatives.includes(field.value.toString())) {
                return field.value.toString();
            } else {
                if (field.alternatives.length > 0) {
                    return field.alternatives[0];
                }
            }
        }

        return "";
    }

    get extraInputSize(): number {
        return 3;
    }


}
