
































































































































































import {Component, Mixins, Prop} from 'vue-property-decorator';
import {API} from '@/services/api-provider';
import {IndividualSearchResponse} from "@/models/individual-search-response";
import {FrontendHousehold} from "@/models/frontend-household";
import {FrontendIndividual} from "@/models/frontend-individual";
import {FrontendAddressLocation} from "@/models/frontend-address-location";
import {Subscription} from "@/models/subscription";
import Utils from "@/mixins/utils";
import ReadOnlyField from "@/components/ReadOnlyField.vue";
import PhoneLinkField from "@/components/PhoneLinkField.vue";
import PnrLinkField from "@/components/PnrLinkField.vue";
import SparIndividual from "@/components/SparIndividual.vue";
import SimpleMap from "@/components/SimpleMap.vue";
import EditableColumnOfFields, {createField, Field} from "@/components/EditableColumnOfFields.vue";
import {booleanTextFilter} from "@/services/filters";
import PnrLink from "@/components/PnrLink.vue";

@Component({
    components: {PnrLink, EditableColumnOfFields, ReadOnlyField, PhoneLinkField, PnrLinkField,SparIndividual, SimpleMap}//, LMap, LTileLayer, LMarker}
})
export default class Individual extends Mixins(Utils) {
  
  @Prop()
  id: string;
  // Search related variables
  private searching: boolean = false;
  
  private badSearch: boolean = false;

  private errorMessage: string = "";
  
  // Spar search related variables
  private sparSearchError: null | any = null;
  
  private sparSearchInProgress: boolean = false;
  
  private sparSearchVerify: boolean = false;
  
  // Search result models
  private individual: FrontendIndividual = null!;
  
  private household: FrontendHousehold = null!;
  
  private addressLocation: FrontendAddressLocation = null!;
  
  private addressLocations: any[] = [];
  
  private subscriptions: Subscription[] = [];
  
  private events: any[] = [];
  
  private sparSearchResponse: any = null!;

  private sparAbortController: undefined | AbortController;

  // Query
  private query: any = {
      personalNumber: ""
  }

  beforeCreate() {
      this.$store.dispatch("setCurrentState","INDIVIDUAL-SEARCH")    
  }
  
  mounted() {
      
      const queryPnr = this.$route.query.pnr;
      if(queryPnr){
          this.query.personalNumber=queryPnr;
          this.search();
      }
  }
  
  search() : Promise<void> {
      return new Promise<void>(resolve => {
          this.badSearch = false;
          this.searching = true;
          API.searchIndividual(this.query).then((data: IndividualSearchResponse) => {
              this.subscriptions = data.subscriptions;
              this.addressLocation = data.addressLocation;
              this.household = data.household;
              this.individual = data.individual;
              this.events = data.events;
              if (data.fbfAddressLocation) {
                  this.addressLocations.push({
                      type: "fbf",
                      al: data.fbfAddressLocation
                  })
              }
              if (data.spAddressLocation) {
                  this.addressLocations.push({
                      type: "sp",
                      al: data.spAddressLocation
                  })
              }
              this.sparSearchResponse = data.personsok;

          }).catch((resp: any) => {
              this.errorMessage = this.extractErrorMessage(resp);
              console.log(this.errorMessage);
              this.badSearch = true;
          }).finally(() => {
              this.searching = false;
              resolve();
          });
     });
  }

  searchSpar() {
      /*
      if (!searchCtrl.response || !searchCtrl.response.individual) {
          return;
      }*/

      if (this.sparAbortController) {
          this.sparAbortController.abort();
      }
      this.sparAbortController = new AbortController();
      this.sparSearchInProgress = true;
      this.sparSearchVerify = false;
      this.sparSearchError = null;

      API.searchIndividualSpar(this.query, this.sparAbortController.signal)
          .then(data => {
              this.sparSearchResponse = data.response;
          })
          .catch(error => {
              this.sparSearchError = error;
          })
          .finally(() => {
              this.sparSearchInProgress = false;
              this.sparAbortController = undefined;
          });
  }
  
  hasAnyMatchedData(): boolean {
    let hasPhones = this.subscriptions && this.subscriptions.length > 0;
    let hasHousehold = this.household;
    let hasAddressLocation = this.addressLocation;
    if (hasPhones || hasHousehold || hasAddressLocation) {
        return true;
    }
    return false;
  }
  
  getHouseholdMembers(): FrontendIndividual[] {
      let toReturn: FrontendIndividual[] = [];
      
      if (this.household) {
          for (let i = 0; i < this.household.members.length; i++) {
              if (this.household.members[i].personalNumber !==
                  this.individual.personalNumber) {
                  toReturn.push(this.household.members[i]);
              }
          }
      }
      
      return toReturn;
  }
  
  hasSparHitToImport(): boolean {
      if (!this.sparSearchResponse) {
          return false;
      }
      return this.sparSearchResponse.resultType === 'HIT';
  }

  get houseHoldFields(): Field[] {
      return [
          createField("Boendeform", this.household.housingType),
          createField("Hushållstyp", this.household.compositionType),
          createField("Medlemmar", null, false, 'custom')
      ]
  }

  addressLocationFields(al: FrontendAddressLocation): Field[] {
      return [
          createField("Gatuadress", al.streetAddress),
          createField("Gatunamn", al.streetName),
          createField("Gatunummer", al.streetNumber),
          createField("Littera", al.entrance),
          createField("Postnummer", al.zip),
          createField("Postort", al.city),
          createField("Ägartyp", al.propertyOwnerType),
          createField("X-koordinat", al.xCoord),
          createField("Y-koordinat", al.yCoord),
          createField("Latitud", al.latitude),
          createField("Longitud", al.longitude),
          createField("Adresskällor", al.sources),

      ]
  }

  subscriptionFields(subscription: Subscription): Field[] {
      const hasPersonalNumber = !!subscription.userPersonalNumber;
      return [
          createField("Indata id", subscription.operatorPhoneId),
          createField("Företagsnummer", booleanTextFilter(subscription.company)),
          createField("Organisationsnummer", subscription.orgNo, false, subscription.company ? undefined : 'hideRow'),
          createField("Personnummer indata", hasPersonalNumber ? subscription.userPersonalNumber.longForm : "",
          false,  !subscription.company && hasPersonalNumber ? undefined : 'hideRow'),
          createField("Nummer", subscription.phoneNumber, false, 'phone'),
          createField("Typ", subscription.phoneType),
          createField("Subtyp", subscription.originalType),
      ]
  }
}
