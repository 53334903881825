





































import Vue from "vue";
import {Component, Emit, Prop} from "vue-property-decorator";
import ReadOnlyField from "@/components/ReadOnlyField.vue";
import {
    mockExportFilter,
    ExportFilter,
    ExportFilterField,
    defaultExportFilterFile,
    defaultExportRowFilter,
    ExportFilterFile,
    ExportFilterRowFilter,
    CreateExportFilterRequest,
    MockExportFilter, ExportType
} from "@/models/frontend-export-filter";
import TextWithButton from "@/components/TextWithButton.vue";
import EditableWrapRow from "@/components/EditableWrapRow.vue";
import EditableColumnOfFields, {
    createField,
    Field, ValueType
} from "@/components/EditableColumnOfFields.vue";
import ColumnPair from "@/components/ColumnPair.vue";

/*
Design comments:
The exportFilterInput is used as original data and is never modified.
exportFilter is changed "live" when user adds or deletes to the free lists of fields but it is not changed live when user
changes the fields with fixed name. I.e. live changes are made when editing data represented with string[] but not when
editing a field with single string.

The changes from the single string fields are read in the saveChanges function and there patched onto the exportFilter
object which is then returned. This is because exportFilter is used to render the read only fields so we do not want
to update it immediately.
 */
/**
 * Component that displays and provides editing of {@link ExportFilter}.
 *
 * @author hugosave 2022-07-13
 */
@Component({components: {
        ColumnPair,
        EditableColumnOfFields, EditableWrapRow, TextWithButton, ReadOnlyField}})
export default class ExportFilterComponent extends Vue {

    @Prop()
    exportFilterInput: ExportFilter | MockExportFilter;

    // Needed to assign this immediately to make it reactive.
    exportFilter: ExportFilter | MockExportFilter = mockExportFilter();

    editMode: boolean = false;

    created() {
        this.deepCopyInput();
    }

    deepCopyInput() {
        this.exportFilter = JSON.parse(JSON.stringify(this.exportFilterInput));
    }

    /**
     * Main info fields. Must match with {@link mainInfoFieldsIndices} in order to parse the updated data correctly.
     */
    mainInfoFields(): Field[] {
        return [
            createField("Id", "id" in this.exportFilter ? this.exportFilter.id : "Skapas när du sparar filtret", false),
            createField("Kund", this.exportFilter.customer)
        ]
    }

    private static mainInfoFieldsIndices() {
        return {
            "id": 0,
            "customer": 1
        }
    }

    /**
     * Fields of each file filter. Must match with {@link fileFilterIndices}:
     */
    fileFilterFields(fileFilter: ExportFilterFile): Field[] {
        let exportTypes = [];
        for (const exportType in ExportType) {
            exportTypes.push(exportType);
        }
        return [
            createField("Exporttyp", fileFilter.exportType, true,  undefined, exportTypes),
            createField("Filprefix", fileFilter.filePrefix),
            createField("Sortera enligt", fileFilter.sortBy)
        ]
    }

    private static fileFilterIndices() {
        return {
            exportType: 0,
            filePrefix: 1,
            sortBy: 2,
        }
    }

    /**
     * Files of each row filter. Must match with {@link rowFilterIndices}.
     */
    rowFilterFields(rowFilter: ExportFilterRowFilter): Field[] {
        return [
            createField("Fält att filtrera över", rowFilter.filterField),
            createField("Filtrera rad om fält är lika med", rowFilter.filterOnEquals)
        ]
    }

    private static rowFilterIndices() {
        return {
            filterField: 0,
            filterOnEquals: 1,
        }
    }

    /**
     * This function actively fetches the data from each of the fields in form. This is by done having refs (https://vuejs.org/guide/essentials/template-refs.html)
     * to each of the forms and then calling the getFieldData() on each of them. From this data we fill in the data on
     * the exportFilter. To do that mapping we have a few static functions that maps each index of data from the
     * getFieldData to the right element of the exportFilter. Lastly the updated exportFilter is emitted.
     *
     */
    saveChanges() {
        // Getting the uppermost fields are straight forward since there is always exactly one such column of fields
        const mainFields: ValueType[] = (this.$refs.mainFields as EditableColumnOfFields).getFieldData().map(field => field.value);
        const mainIndices = ExportFilterComponent.mainInfoFieldsIndices();

        this.exportFilter.customer = mainFields[mainIndices.customer] as string;

        if (this.$refs.fileFilters) {
            // Since we create file-filters with a v-for loop we get an array of them which all in turn have an array of data.
            const fileFilters: ValueType[][] =
                (this.$refs.fileFilters as EditableColumnOfFields[]).map(vueComponent => vueComponent.getFieldData().map(field => field.value));
            const fileFilterIndices = ExportFilterComponent.fileFilterIndices();
            const rowFilterIndices = ExportFilterComponent.rowFilterIndices();

            // Loop through data of each filefilter
            for (const [i, newFileFilter] of fileFilters.entries()) {
                let fileFilter = this.exportFilter.files[i];
                // Fill in file filter data
                fileFilter.exportType = newFileFilter[fileFilterIndices.exportType] as ExportType;
                fileFilter.filePrefix = newFileFilter[fileFilterIndices.filePrefix] as string;
                fileFilter.sortBy = newFileFilter[fileFilterIndices.sortBy] as string;

                // Since row filters are created in a nested v-for loop, vue appends an index at the end corresponding
                // to the outer for loop index.
                if (this.$refs['rowFilters' + i]) {
                    const rowFiltersData = (this.$refs['rowFilters' + i] as EditableColumnOfFields[]).map(obj => obj.getFieldData().map(field => field.value));
                    for (const [j, newRowFilter] of rowFiltersData.entries()) {
                        // Fill in row filter data
                        fileFilter.rowFilters[j].filterOnEquals = newRowFilter[rowFilterIndices.filterOnEquals] as string;
                        fileFilter.rowFilters[j].filterField = newRowFilter[rowFilterIndices.filterField] as string;
                    }
                }

                // this.exportFilter is now updated with the changes done since fileFilter was just a reference to it
            }
        }
        if ("id" in this.exportFilter) {
            this.updateExportFilter(this.exportFilter);
        } else {
            this.createExportFilter(this.exportFilter);
        }
    }

    /**
     * Because of how {@link ExportFilterField} has the string value encapsulated we need to do some conversion since
     * {@link EditableWrapRow} expects and emits string arrays directly.
     */
    updatedFileFields(fileIdx: number, fields: string[]) {
        this.exportFilter.files[fileIdx].fields = fields.map(this.toExportFilterField);
    }

    /**
     * Because of how {@link ExportFilterField} has the string value encapsulated we need to do some conversion since
     * {@link EditableWrapRow} expects and emits string arrays directly.
     */
    updateRowFilter(fileIdx: number, rowFilterIdx: number, fields: string[]) {
        this.exportFilter.files[fileIdx].rowFilters[rowFilterIdx].fieldsToKeep = fields.map(this.toExportFilterField);
    }

    toggleEditMode() {
        // If we leave edit mode then undo all changes
        if (this.editMode)
          this.deepCopyInput();
        this.editMode = !this.editMode;
    }

    /**
     * Creates a new file filter with default values.
     */
    createFileFilter() {
        this.exportFilter.files.push(defaultExportFilterFile());
    }

    createRowFilter(fileFilterIdx: number) {
        this.exportFilter.files[fileFilterIdx].rowFilters.push(defaultExportRowFilter());
    }

    @Emit()
    createExportFilter(exportFilter: ExportFilter | MockExportFilter): CreateExportFilterRequest {
        return this.toCreateRequest(exportFilter);
    }

    @Emit()
    updateExportFilter(exportFilter: ExportFilter): {id:number, exportFilter:CreateExportFilterRequest} {
        return {id: exportFilter.id, exportFilter: this.toCreateRequest(exportFilter)};
    }

    @Emit()
    deleteExportFilter(): void {
    }

    toCreateRequest(exportFilter: ExportFilter | MockExportFilter): CreateExportFilterRequest {
        return {
            customer: exportFilter.customer,
            files: exportFilter.files
        }
    }

    private flattenField(field: ExportFilterField): string {
        return field.field;
    }

    private toExportFilterField(str: string): ExportFilterField {
        return {field: str};
    }

}
