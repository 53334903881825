
































import { Component,Mixins } from 'vue-property-decorator';
//import {HTTP} from "@/services/http"
import {API} from "@/services/api-provider"
import {FrontendHousehold} from "@/models/frontend-household";
import Utils from "@/mixins/utils";
import ReadOnlyField from "@/components/ReadOnlyField.vue";
import HouseholdMemberDetailedLink from "@/components/HouseholdMemberDetailedLink.vue";
import EditableColumnOfFields, {createField, Field} from "@/components/EditableColumnOfFields.vue";

@Component({components: {EditableColumnOfFields, ReadOnlyField, HouseholdMemberDetailedLink}})
export default class Household extends Mixins(Utils) {
    
    private searching: boolean = false;
    private badSearch: boolean = false;
    private errorMessage: string = "";
    // Query
    private query: any = {
        personalNumber: ""
    }
    
    private household: FrontendHousehold = null!;
    
    beforeCreate() {
        this.$store.dispatch("setCurrentState","HOUSEHOLD-SEARCH")    
    }
    
    mounted() {
        
        const queryPnr = this.$route.query.pnr;
        if(queryPnr){
            this.query.personalNumber=queryPnr;
            this.search();
        }
    }
    
    search() : Promise<void> {
        if(this.searching) {
            return new Promise<void>(()=> console.log("already searching"));
        }
        return new Promise<void>(resolve => {
            this.badSearch = false;
            this.searching = true;
            API.searchHousehold(this.query).then((data: FrontendHousehold) => {
                this.household = data
                
            }).catch((resp: any) => {
                this.errorMessage = this.extractErrorMessage(resp);
                console.log(this.errorMessage);
                this.badSearch = true;
            }).finally(() => {
                this.searching = false;
                resolve();
            });
       });
    }

    get householdFields(): Field[] {
        return [
            createField("Boendeform", this.household.housingType),
            createField("Hushållstyp", this.household.compositionType),
            createField('Hushåll', undefined, false, 'custom')
        ]
    }
}
