
export type FtpConfiguration = {
    binaryMode: boolean;
    directory: string;
    ftpProtocol: string;
    hostName: string;
    passiveMode: boolean;
    password: string;
    port: number;
    properties: FtpConfigurationProperty[];
    username: string;
};

export enum FtpConfigurationProperty {
    IGNORE_SSL_VERIFY_CERTIFICATE = "IGNORE_SSL_VERIFY_CERTIFICATE"
}

export function FtpConfigurationPropertyList() {
    const list = []
    for (const type in FtpConfigurationProperty) {
        list.push(type)
    }
    return list;
}

export function defaultFtpConfig(): FtpConfiguration {
   return {
       binaryMode: true,
       properties: [FtpConfigurationProperty.IGNORE_SSL_VERIFY_CERTIFICATE],
       directory: "",
       username: "",
       passiveMode: true,
       ftpProtocol: "FTP",
       hostName: "",
       password: "",
       port: 21,
   }
}