

























import { Component,Mixins } from 'vue-property-decorator';
//import { HTTP } from "@/services/http";
import Utils from "@/mixins/utils";
import AUTH from "@/services/auth";
//import STORE from "@/store/store";

@Component({})
export default class Login extends Mixins(Utils) {
        private auth: any = {
            username:"",
            password:""
        }
        private errorMessage: string = "";
        async login() {
            AUTH.login(this.auth).then(() => {
                this.$router.push("/home");
            }).catch((err: any) => {
                this.errorMessage = this.extractErrorMessage(err);
                alert(this.errorMessage);
                console.log(this.errorMessage);
            });
        }
}

