import {SubscriptionWithFrontendIndividual} from "@/models/subscription-with-frontend-individual";
import {PhoneNumberDisplayBlock} from "@/models/phone-number-display-block";

/**
 * See documentation for the corresponding java class.
 */
export class PhoneSearchResponse {
    
    subscriptions: SubscriptionWithFrontendIndividual[];
    
    blocks: PhoneNumberDisplayBlock[];     
}