














import Vue from 'vue';
//import markerIconPng from "leaflet/dist/images/marker-icon.png"
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import { icon } from "leaflet";

import 'leaflet/dist/leaflet.css';
import {Component,Prop} from 'vue-property-decorator';

@Component({
    components: {LMap, LTileLayer, LMarker}
})
export default class SimpleMap extends Vue {
    @Prop() 
    lat: number;
    
    @Prop() 
    lng: number;
    
    zoom: number = 15;
    
    get latLng() {
        const tmpLat = this.lat ? this.lat : 0;
        const tmpLng = this.lng ? this.lng : 0;
        return [tmpLat,tmpLng];
    }
    
    get markerIcon() {
        return icon({
            iconRetinaUrl: require('@/assets/marker-icon-2x.png'),
            iconUrl: require('@/assets/marker-icon.png'),
            shadowUrl: require('@/assets/marker-shadow.png'),
            iconSize: [25, 41],
            iconAnchor: [16, 37]
        });
    }
    get attribution() {
        return '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors';
    }
    /*
    markerIcon: any = {
        iconUrl: "leaflet/dist/images/marker-icon.png",
        iconSize: [32, 37],
        iconAnchor: [16, 37]
    }*/
}
