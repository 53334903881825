import Axios, {
    AxiosError,
    AxiosInstance,
    AxiosRequestConfig,
    AxiosResponse
} from "axios";
import { Global } from "@/global";
import STORE from "@/store/store";
import router from "@/router";
import Auth from "@/services/auth";

/**
 * Extension to the request configuration that allows us to specify own
 * attributes to be used for example in interceptors.
 */
export interface ExtendedAxiosRequestConfig extends AxiosRequestConfig {
    /**
     * The number of times to retry a request if it failed.
     */
    numRetries?: number;

    /**
     * Number of times tried so far.
     */
    __retryCount?: number;

    /**
     * The delay in milliseconds between each retry.
     */
    retryDelayMs?: number;

    /**
     * If the response should not be caught and processed in any receptor
     */
    dontCatch?: boolean;
}

export class Http {

    /**
     * The axios client to use.
     */
    private axiosClient: AxiosInstance;

    constructor() {


        // The ApiClient wraps calls to the underlying Axios client.
        this.axiosClient = Axios.create({
            timeout: 60000,
            headers: {
                "X-Initialized-At": Date.now().toString()
            }
        });
        
        this.axiosClient.interceptors.response.use((response: any) => {
            return response;
        }, (error: any) => {
            // Instant logout if any unauthorized request appears.
            if(error.response && error.response.status === 401) {
                Auth.logout();
                router.push({name: "logout"});
            }
            if (error.response && error.response.data) {
                 //add your code
                 return Promise.reject(error.response.data);
            }
            return Promise.reject(error.message);
        });
    }


    private request<T>(config: ExtendedAxiosRequestConfig): Promise<T> {
        if (!config.headers) {
            config.headers = {};
        }
        config.headers["Content-Type"] = "application/json;charset=UTF-8";
        const token = STORE.state.token;//localStorage.getItem("token");
        if(token) {
            config.headers["Authorization"] = "Bearer "+ token;
        }

        // Always prepend the base url to all requests.
        config.url = Global.url(config.url!);

        return new Promise<T>((resolve, reject) => {
            this.axiosClient.request<T>(config)
                .then((response: AxiosResponse<T>) => resolve(response.data))
                .catch((error: AxiosError) => {
                    // Reject error response if the response and the error
                    // exists, otherwise just reject error (which might be
                    // undefined or null).
                    if (error) {
                        reject(error.response || error);
                    } else {
                        reject(error)
                    }
                });
        });
    }
    /**
     * Performs a get request to the given path (not url) with the provided
     * parameters. The specified id will be included in the wrapped response,
     * which makes it possible for the caller to know which response that is
     * returned. This can be useful when one wants to discard all old requests
     * if the response for a later request already has arrived. In that case, a
     * simple counter will suffice as id.
     *
     * @param path The path to post to.
     * @param params Optional parameters.
     * @param abortSignal Controller to abort the request.
     * @param numRetries The number of times to retry a failed request.
     */
    public async get<T>(path: string, params?: any, abortSignal?: AbortSignal, numRetries: number = 0): Promise<T> {
        const config: ExtendedAxiosRequestConfig = {
            method: "get",
            url: path,
            params: params,
            numRetries: numRetries,
            signal: abortSignal
        };
        try {
            return await this.request(config);
        } catch (error) {
            return (Promise.reject(error));
        }
    }

    /**
     * Performs a post request to the given path (not url) with the provided
     * body. The specified id will be included in the wrapped response, which
     * makes it possible for the caller to know which response that is
     * returned. This can be useful when one wants to discard all old requests
     * if the response for a later request already has arrived. In that case, a
     * simple counter will suffice as id.
     *
     * @param path The path to post to.
     * @param data The data to post.
     * @param abortSignal signal to enable abortion of the request.
     * @param numRetries The number of times to retry a failed request.
     * @param dontCatch If the response should not be caught by the
     *     interceptors.
     */
    public async post<T>(path: string, data?: any, abortSignal?: AbortSignal, numRetries: number = 0, dontCatch: boolean = false): Promise<T> {
        try {
            const config: ExtendedAxiosRequestConfig = {
                method: "post",
                url: path,
                data: data,
                numRetries: numRetries,
                dontCatch: dontCatch,
                signal: abortSignal
            };
            return await this.request<T>(config);
        } catch (error) {
            return Promise.reject(error);
        }
    }
    /**

     * Performs a post request to the given path (not url) with the provided
     * body. The specified id will be included in the wrapped response, which
     * makes it possible for the caller to know which response that is
     * returned. This can be useful when one wants to discard all old requests
     * if the response for a later request already has arrived. In that case, a
     * simple counter will suffice as id.
     *
     * @param path The path to post to.
     * @param data The data to post.
     * @param abortSignal signal to enable abortion of the request.
     * @param numRetries The number of times to retry a failed request.
     * @param dontCatch If the response should not be caught by the
     *     interceptors.
     */
    public async delete<T>(path: string, data?: any, abortSignal?: AbortSignal, numRetries: number = 0, dontCatch: boolean = false): Promise<T> {
        try {
            const config: ExtendedAxiosRequestConfig = {
                method: "delete",
                url: path,
                data: data,
                numRetries: numRetries,
                dontCatch: dontCatch,
                signal: abortSignal
            };
            return await this.request<T>(config);
        } catch (error) {
            return Promise.reject(error);
        }
    }
}
export const HTTP = new Http();