








import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';

@Component
export default class PhoneLinkField extends Vue {
    @Prop()
    private phone: string;
    
    @Prop()
    label: string;
}
