









import { Component,Mixins } from 'vue-property-decorator';
import Utils from "@/mixins/utils";
import PageHeader from './components/PageHeader.vue';
import AUTH from "@/services/auth";

@Component({
  components: {
      PageHeader
  },
})
export default class App extends Mixins(Utils) {
    created () {
        if(!AUTH.isLoggedIn()) {
            AUTH.logout();
            this.$router.push({ name: "login" });
        } 
        else {
            AUTH.whoami();
        }
    }

    get displayHeader(): boolean {
        return AUTH.isLoggedIn();
    }
}
