



































import Vue from 'vue';
import {Component,Prop} from 'vue-property-decorator';
import {FrontendIndividual} from "@/models/frontend-individual";
import ReadOnlyField from "@/components/ReadOnlyField.vue";
import EditableColumnOfFields, {createField, Field} from "@/components/EditableColumnOfFields.vue";

@Component({ 
    components: {EditableColumnOfFields, ReadOnlyField }
})
export default class SparIndividual extends Vue {
    @Prop() 
    individual: FrontendIndividual;

    get personalDetailFields(): Field[] {
        const individual = this.individual;
        return [
            createField("Status", individual.status),
            createField("Personnummer", individual.personalNumber),
            createField("Gammalt personnumer", individual.oldPersonalNumber),
            createField("Nytt personnummer", individual.newPersonalNumber),
            createField("Födelsedatum", individual.birthDate),
            createField("Registrerad", individual.regDate),
            createField("Avregistrerad", individual.deregDate),
            createField("Senast ändrad", individual.changeDate),
        ]
    }

    get nameFields(): Field[] {
        const individual = this.individual;
        return [
            createField("Namnkod", individual.givenNameCode),
            createField("Förnamn", individual.firstNames),
            createField("Mellannamn", individual.middleNames),
            createField("Efternamn", individual.lastNames),

        ]
    }

    get addressFields(): Field[] {
        const individual = this.individual;
        return [
            createField("C/o-adress", individual.fbfCareOf),
            createField("Gatuadress", individual.fbfStreetAddress),
            createField("Fortsättningsadress", individual.fbfContinuation),
            createField("Postnummer",  individual.fbfZip),
            createField("Postort", individual.fbfCity),

        ]
    }

    get spAddressFields(): Field[] {
        const individual = this.individual;
        return [
            createField("C/o-adress", individual.spCareOf),
            createField("Gatuadress", individual.spStreetAddress),
            createField("Fortsättningsadress", individual.spContinuation),
            createField("Postnummer", individual.spZip),
            createField("Postort", individual.spCity),

        ]
    }

    get foreignAddressFields(): Field[] {
        const individual = this.individual;
        return [
            createField("Adressrad 1", individual.foreignAddress1),
            createField("Adressrad 2", individual.foreignAddress2),
            createField("Adressrad 3", individual.foreignAddress3),
            createField("Land", individual.foreignCountry),

        ]
    }

    get relation1Fields(): Field[] {
        const individual = this.individual;
        return [
            createField("Typ", individual.relation1Type),
            createField("Status", individual.relation1Status),
            createField("Personnummer", individual.relation1PersonalNumber),
            createField("Avregistrerad", individual.relation1DeregDate),
            createField("Födelsedatum", individual.relation1BirthDate),
            createField("Förnamn", individual.relation1FirstNames),
            createField("Mellannamn", individual.relation1MiddleNames),
            createField("Efternamn", individual.relation1LastNames),

        ]
    }

    get relation2Fields(): Field[] {
        const individual = this.individual;
        return [
            createField("Typ", individual.relation2Type),
            createField("Status", individual.relation2Status),
            createField("Personnummer", individual.relation2PersonalNumber),
            createField("Avregistrerad", individual.relation2DeregDate),
            createField("Födelsedatum", individual.relation2BirthDate),
            createField("Förnamn", individual.relation2FirstNames),
            createField("Mellannamn", individual.relation2MiddleNames),
            createField("Efternamn", individual.relation2LastNames),

        ]
    }

    showSpAddress(): boolean {
        if (this.individual) {
            return this.individual.spCareOf !== '' ||
                this.individual.spStreetAddress !== '' ||
                this.individual.spContinuation !== '' ||
                this.individual.spZip !== '' ||
                this.individual.spCity !== '';
        }
        return false;
    }
    
    showForeignAddress(): boolean {
        if (this.individual) {
            return this.individual.foreignAddress1 !== '' ||
                this.individual.foreignAddress2 !== '' ||
                this.individual.foreignAddress3 !== '' ||
                this.individual.foreignCountry !== '';
        }
        return false;
    }
    showRelations(): boolean {
        if (this && this.individual) {
            let ind = this.individual;
            return ind.relation1Type !== 'Okänd' || ind.relation2Type !== 'Okänd'
        }
        return false;
    }

    showRelation(relationType: string): boolean {
        return relationType !== 'Okänd';
    }
}
