




















































































import { Component,Mixins } from 'vue-property-decorator';
import {API} from '@/services/api-provider';
import {PhoneSearchResponse} from "@/models/phone-search-response";
import Utils from "@/mixins/utils";
import ReadOnlyField from "@/components/ReadOnlyField.vue";
import PnrLinkField from "@/components/PnrLinkField.vue";
import {PhoneNumberDisplayBlock} from "@/models/phone-number-display-block";
import {toPrettyTimeStamp} from "@/services/phone_utils";
import EditableColumnOfFields, {createField, Field} from "@/components/EditableColumnOfFields.vue";
import PnrLink from "@/components/PnrLink.vue";
import {Subscription} from "@/models/subscription";

@Component({
    components: {PnrLink, EditableColumnOfFields, ReadOnlyField, PnrLinkField}
})
export default class Phone extends Mixins(Utils) {

    private errorMessage: string = "";
    private searching: boolean = false;
    private hasSearched: boolean = false;
    private query:any = {
            phoneNumber: null
    };
    private response: PhoneSearchResponse = new PhoneSearchResponse;
    private badSearch: boolean = false;
    private togglingBlock: boolean = false;
    

    beforeCreate() {
        this.$store.dispatch("setCurrentState","PHONE-SEARCH")    
    }
    
    mounted() {
        
        const phoneNr = this.$route.query.phoneNr;
        if(phoneNr){
            this.query.phoneNumber=phoneNr;
            this.search();
        }
    }

    /**
     * Gets a nicely formatted string from the raw timestamp
     *
     * @param rawTimeStamp
     */
    prettyTimeStamp(rawTimeStamp: string): string {
        return toPrettyTimeStamp(rawTimeStamp);
    }

    
    search(): Promise<void> {
        if(this.searching) {
            return new Promise<void>(()=> console.log("already searching"));
        }
        return new Promise<void>(resolve => {
            this.badSearch = false;
            
            this.searching = true;
            this.hasSearched = true;
            
            API.searchPhone(this.query).then((data: PhoneSearchResponse) => {
               this.response = data;
            }).catch((resp: any) => {
                this.errorMessage = this.extractErrorMessage(resp);
                console.log(this.errorMessage);
                this.badSearch = true;
            }).finally(() => {
                this.searching = false;
                resolve();
            });
        });
    }
    
    toggleBlock(): void {
        if (!this.response || this. togglingBlock) {
            return;
        }
        this.togglingBlock = true;
        let swi = this.getLatestBlockableSubscription();
        if (swi) {
            this.togglingBlock = true;
            API.toggleBlock({subscription: swi.subscription.id}).then((data: any) => {
                this.response.blocks = data.blocks;
            }).catch((resp: any) => {
                this.errorMessage = this.extractErrorMessage(resp);
                console.log(this.errorMessage); 
            });
            
        }
        this.togglingBlock = false;
    }
    
    isBlockActive(): boolean {
        return this.response && this.response.blocks && this.response.blocks.length > 0 && this.response.blocks[0].blocked;
    }
    
    canHaveBlock(): boolean {
        if (!this.response) {
            return false;
        }
        return !!this.getLatestBlockableSubscription();
    }

    phoneDataLeft(subscription: Subscription): Field[] {
        return [
            createField("Operatörs-id", subscription.operatorId),
            createField("Nummer", subscription.phoneNumber),
            createField("Posttyp", subscription.recordType),
            createField("Originaltyp", subscription.originalType),
            createField("Telefontyp", subscription.phoneType),
            createField("Företagsnummer", subscription.company),
            createField("Personnummer", undefined, false,  'custom'),
            createField("Orgnr", subscription.orgNo),
            createField("Ägar-id", subscription.ownerId),
            createField("Förnamn", subscription.firstNames),
            createField("Mellannamn", subscription.middleNames),
            createField("Efternamn", subscription.lastNames),

        ]
    }

    phoneDataRight(subscription: Subscription): Field[] {
        return [
            createField("Företagsnamn", subscription.companyName),
            createField("C/o-adress", subscription.careOf),
            createField("Gatuadress", subscription.streetAddress),
            createField("Postnummer", subscription.zip),
            createField("Ort", subscription.city),
            createField("Adresstyp", subscription.addressType),
            createField("Förnamn användare", subscription.userFirstNames),
            createField("Mellannamn användare", subscription.userMiddleNames),
            createField("Efternamn användare", subscription.userLastNames),

        ]
    }
   
    formatBlockDate(block: PhoneNumberDisplayBlock): string {
        return block.created;
    }
    
    getLatestBlockableSubscription() {
        return this.response.subscriptions.find(function (swi: any) {
            let hasOrgNo = swi.subscription.company && swi.subscription.orgNo > 0;
            return swi.subscription.supersededInFileId === 0 && (hasOrgNo || swi.individual)
        });
    }
}

