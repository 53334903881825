import Vue from "vue"

export const booleanTextFilter = (bool: boolean): string => {
    if (bool) {
        return "Ja";
    }
    return "Nej";
}
Vue.filter("bool", booleanTextFilter);


const divideBy = (val: number, by: number): any => {
    if (val === null) {
        return '';
    }
    if (!isNaN(+val)) {
        return val;
    }
    return Number(val) / Number(by);
}
Vue.filter("divideBy", divideBy);



const numberFilter = (input: string, numDecimals: number = -1): string => {
    if (input === '' || input == null) {
        return '—';
    }
    const parts = String(input).split('.');
    if (parts.length > 2) {
        return '---';
    }

    let decimalPart = '';
    let numberPart = parts[0];
    if (parts.length === 2) {
        decimalPart = parts[1];
    }

    if (numberPart.length === 0) {
        numberPart = '0';
    }

    let result = '';

    const len = numberPart.length;

    /**
     * Build the integer part backwards and insert a space every three digits.
     */
    for (let i = 1; i <= len; i++) {
        result = numberPart.charAt(len - i) + result;
        if (i % 3 === 0 && i !== len) {
            result = '\xa0' + result; // Non breaking space.
        }
    }

    if (numDecimals != -1) {
        while (decimalPart.length < numDecimals) {
            decimalPart += '0';
        }
        if (decimalPart.length > numDecimals) {
            decimalPart = decimalPart.substr(0, numDecimals);
        }
    }

    /**
     * Always use comma as decimal separator.
     */
    if (decimalPart.length > 0) {
        result = result + ',' + decimalPart;
    }

    return result;
}
Vue.filter("num", numberFilter);
