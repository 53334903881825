








import {Component, Prop} from 'vue-property-decorator';
import Vue from "vue";

/**
 * Component that toggles rendering of content on a button click.
 */
@Component({})
export default class Foldable extends Vue{
    @Prop()
    buttonName: string;

    /**
     * Show content as default
     */
    @Prop({
        required: false
    })
    showContentProp?: boolean;

    showContent: boolean = false;

    created() {
        if (this.showContentProp)
            this.showContent = this.showContentProp;
    }
}
