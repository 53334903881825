import {FtpConfiguration, defaultFtpConfig} from "@/models/ftp-configuration";
import {ExportFilter, ExportType} from "@/models/frontend-export-filter";

/**
 * Frontend class for representing a newly made definition that has not been sent to the server yet.
 */
export type MockFrontendDeliveryDefinition = {
    name: string;

    type: ExportType;

    includeFilePattern: string;

    includeNewerThan?: Date;

    exportFilter?: ExportFilter;

    active: boolean;

    ftpConfiguration: FtpConfiguration;
}

export function defaultMockDefinition(): MockFrontendDeliveryDefinition {
    return {
        name: "",
        active: false,
        type: ExportType.BASIC,
        includeFilePattern: "",
        ftpConfiguration: defaultFtpConfig()
    }
}

/**
 * See documentation for the corresponding java class.
 */
export type FrontendDeliveryDefinition = MockFrontendDeliveryDefinition & {
    id: number;
    created: Date;
}

export function frontendDelivDefFromJson(json: JsonFrontendDeliveryDefinition): FrontendDeliveryDefinition {
    return Object.assign(json, {
        created: new Date(json.created),
        includeNewerThan: json.includeNewerThan ? new Date(json.includeNewerThan) : undefined
    });
}

/**
 * JSON representation of {@link FrontendDeliveryDefinition}.
 */
export type JsonFrontendDeliveryDefinition = Omit<FrontendDeliveryDefinition, "created" | "includeNewerThan"> & {
    created: string;
    includeNewerThan?: string;
}



export function isNotMock(deliveryDef: FrontendDeliveryDefinition | MockFrontendDeliveryDefinition): deliveryDef is FrontendDeliveryDefinition {
    return "id" in deliveryDef;
}
