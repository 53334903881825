

























import Vue from "vue";
import {Component} from "vue-property-decorator";
import {API} from "@/services/api-provider";
import {AuthorisedSignatoryResponse} from "@/models/authorised-signatory-response";

@Component({})
export default class CompanyAuthorisedSignatory extends Vue {

    orgNo: string = "";

    searching: boolean = false;

    badSearch: boolean = false;

    noHits: boolean = false;

    response: AuthorisedSignatoryResponse = {};

    abortController?: AbortController;

    beforeCreate() {
        this.$store.dispatch("setCurrentState","COMPANY-AUTHORISED-SIGNATORY")
    }

    search() {

        this.badSearch = false;

        // Cancel any ongoing request..
        if (this.abortController) {
            this.abortController.abort();
        }
        this.abortController = new AbortController();
        this.searching = true;
        API.searchCompanyAuthorisedSignatory(this.orgNo.replaceAll("-", ""), this.abortController.signal).then((response) => {
            this.noHits = !response.authorisedSignatory;
            this.response = response;
            this.searching = false;
        })
            .catch(() => this.badSearch = true)
            .finally(() => this.abortController = undefined);
    }
}
