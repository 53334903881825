














import Vue from 'vue';
import {Component, Emit, Prop} from "vue-property-decorator";

/**
 * Renders a text field or dropdown besides a button that on click emits the text data or dropdown alternative.
 */
@Component({})
export default class TextInputWithButton extends Vue {

    @Prop()
    placeholder: string;

    @Prop({required: false})
    options: string[];

    @Prop()
    buttonName: string;

    inputContent = "";

    btnClicked() {
        if (this.inputContent != "") {
          const tmp = this.inputContent;
          this.inputContent = "";
          this.click(tmp);
        }
    }

    @Emit()
    click(str: string): string {
        return str;
    }
}
