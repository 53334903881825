





























import { Component,Mixins } from 'vue-property-decorator';
import Utils from "@/mixins/utils";
import AUTH from "@/services/auth";

@Component({})
export default class PageHeader extends Mixins(Utils) {
    
    get currentState() {
        return this.$store.state.currentState;
    }

    get user() {
         return this.$store.state.user;
    }
    get displayName() {
        return this.getDisplayName(this.user);
    }
    
    get isLoggedIn() {
        return AUTH.isLoggedIn();
    }
    
    logout() {
        AUTH.logout();
        this.$router.push("logout");
    }

    linkTo(pathName: string): {name: string} {
        return {name: pathName}
    }
}
