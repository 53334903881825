
















import Vue from "vue";
import {Component, Emit, Prop} from "vue-property-decorator";
import ReadOnlyField from "@/components/ReadOnlyField.vue";
import EditableColumnOfFields, {Field} from "@/components/EditableColumnOfFields.vue";

/**
 * Displays two columns where the left is read only and the right is an editable copy.
 */
@Component({components: {EditableColumnOfFields, ReadOnlyField}})
export default class ColumnPair extends Vue {

    /**
     * Values to display.
     */
    @Prop()
    value: Field[];

    /**
     * Used to toggle the editable right column field.
     */
    @Prop({required:false, default: null})
    editable: boolean | null;

    /**
     * Used for parents to explicitly get data if they do now want to react to events.
     */
    getFieldData(): Field[] {
        if (this.$refs.editColumn) {
            return (this.$refs.editColumn as EditableColumnOfFields).getFieldData();
        } else {
            return this.value;
        }
    }

    /**
     * Forward event upwards.
     * @param e
     */
    @Emit()
    input(e: Field[]): Field[] {
        return e;
    }

}
