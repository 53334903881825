








import Vue from 'vue';
import {Component,Prop} from 'vue-property-decorator';
import {GIVEN_NAME_SERVICE} from "@/services/given-name-service";
import {FrontendIndividual} from "@/models/frontend-individual";
import PnrLink from "@/components/PnrLink.vue";
@Component({
    components: {PnrLink}
})
export default class PnrLinkField extends Vue {
    @Prop()
    withName: boolean;

    @Prop()
    label: string;
    
    @Prop()
    individual: FrontendIndividual;
    
    renderFirstNames(firstNames: string, givenNameCode: string): string {
        return GIVEN_NAME_SERVICE.renderFirstNames(firstNames, givenNameCode);
    }
    
}
