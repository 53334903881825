











import Vue from 'vue';
import {Component,Prop} from 'vue-property-decorator';
import {GIVEN_NAME_SERVICE} from "@/services/given-name-service";
import {FrontendIndividual} from "@/models/frontend-individual";

@Component
export default class PnrLink extends Vue {
    @Prop()
    withName: boolean;

    @Prop()
    individual: FrontendIndividual;
    
    renderFirstNames(firstNames: string, givenNameCode: string): string {
        return GIVEN_NAME_SERVICE.renderFirstNames(firstNames, givenNameCode);
    }
    
}
