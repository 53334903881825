import {HTTP} from "@/services/http";
import {AddressSearchResponse} from "@/models/address-search-response";
import {DeliveryDefinitionResponse}from "@/models/delivery-definition-response";
import {IndividualSearchResponse}from "@/models/individual-search-response";
import {PhoneSearchResponse}from "@/models/phone-search-response";
import {FrontendAddressLocationWithHouseholds} from "@/models/frontend-address-location-with-households";
import {
    frontendDelivDefFromJson,
    FrontendDeliveryDefinition
} from "@/models/frontend-delivery-definition";
import {FrontendHousehold} from "@/models/frontend-household";
import {HouseholdSearchResponse} from "@/models/household-search-response";
import {CompanySearchQuery} from "@/models/CompanySearchQuery";
import {CompanySearchResponse} from "@/models/company-search-response";
import {RawCompanyRecordResponse} from "@/models/raw-company-record-response";
import {AuthorisedSignatoryResponse} from "@/models/authorised-signatory-response";
import {CreateDeliveryDefinitionRequest} from "@/models/create-delivery-definition-request";
import {CreateExportFilterRequest, ExportFilter} from "@/models/frontend-export-filter";

export default class Api {
    
    public async searchAddress(query: any): Promise<FrontendAddressLocationWithHouseholds[]> {
        
        return HTTP.post<AddressSearchResponse>("/rest/search/address",query).then((response: AddressSearchResponse) => {
           return response.addressLocations;
        });
    }

    public async searchIndividual(query: any): Promise<IndividualSearchResponse> {
        return HTTP.post<any>("/rest/search/individual",query).then((response: any) => {
           return response.response;
        });
    }

    public async searchIndividualSpar(query: any, abortSignal?: AbortSignal): Promise<any> {
        return HTTP.post("/rest/search/individual/spar", query, abortSignal);
    }
    
    public async searchPhone(query: any): Promise<PhoneSearchResponse> {
        return HTTP.post<any>("/rest/search/phone",query).then((response: any) => {
           return response;
        });
    }
    
    public async searchHousehold(query: any): Promise<FrontendHousehold> {
        return HTTP.post<HouseholdSearchResponse>("/rest/search/household",query).then((response: HouseholdSearchResponse) => {
           return response.household;
        });
    }
    
    public async searchCompany(query: CompanySearchQuery): Promise<CompanySearchResponse> {
        return HTTP.post<CompanySearchResponse>("/rest/search/company",query).then((response: CompanySearchResponse) => {
           return response;
        });
    }

    public async searchCompanyRaw(query: CompanySearchQuery): Promise<RawCompanyRecordResponse> {
        return HTTP.post<RawCompanyRecordResponse>("rest/search/company-raw", query);
    }

    public async getCompanyRefData(): Promise<any> {
        return HTTP.get("/rest/search/company-ref-data").then((response: any) => {
            return response;
        });
        
    }
    public async getCompanyFields(): Promise<any> {
        return HTTP.get("/rest/search/company-fields").then((response: any) => {
            return response;
        
        });
    }
    
    public async toggleBlock(query: any): Promise<any> {
        return HTTP.post<any>("/rest/admin/phone-block",query).then((response: any) => {
            return response;
        })
    }

    public async searchCompanyAuthorisedSignatory(orgNo: string, abortSignal?: AbortSignal): Promise<AuthorisedSignatoryResponse> {
        return HTTP.post( "/rest/search/company-authorised-signatory", orgNo, abortSignal);
    }

    public async getDeliveryDefinitions(): Promise<FrontendDeliveryDefinition[]> {
        return HTTP.get<DeliveryDefinitionResponse>("/rest/admin/delivery-definitions").then((response: DeliveryDefinitionResponse) => {
            return response.deliveryDefinitions.map(json => {return frontendDelivDefFromJson(json)});
        });
    }

    public async updateDeliveryDefinition(id: number, query: CreateDeliveryDefinitionRequest): Promise<null> {
        return HTTP.post("/rest/admin/delivery-definitions/" + id, query);
    }

    public async createDeliveryDefinition(query: CreateDeliveryDefinitionRequest): Promise<null> {
        return HTTP.post("/rest/admin/delivery-definitions/", query);
    }

    public async deleteDeliveryDefinition(id: number): Promise<null> {
        return HTTP.delete("/rest/admin/delivery-definitions/" + id);
    }

    public async getExportFilters(): Promise<ExportFilter[]> {
        return HTTP.get("/rest/admin/export-filters");
    }

    public async createExportFilter(query: CreateExportFilterRequest) {
        return HTTP.post("/rest/admin/export-filters", query);
    }

    public async updateExportFilter(id: number, query: CreateExportFilterRequest) {
        return HTTP.post("/rest/admin/export-filters/" + id, query);
    }

    public async deleteExportFilter(id: number) {
        return HTTP.delete("/rest/admin/export-filters/" + id);
    }
}