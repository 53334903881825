import Vue from 'vue';
import L from 'leaflet';
import App from './App.vue';
import router from './router'
import store from './store/store'
import { Route } from "vue-router";
import AUTH from "@/services/auth";
import './services/filters'
/*
import auth from './services/auth'
import jwt_decode from 'jwt-decode';
*/

//delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    /*
   iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
   iconUrl: require('leaflet/dist/images/marker-icon.png'),
   shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
   */
   iconRetinaUrl: require('@/assets/marker-icon-2x.png'),
   iconUrl: require('@/assets/marker-icon.png'),
   shadowUrl: require('@/assets/marker-shadow.png'),
   
});

Vue.config.productionTip = false

router.beforeEach((to: Route, from: Route, next: any) => {
    if(to.name==="login" && AUTH.isLoggedIn()) {
        next({
            name: "home"
        })
    }
    next();
});

Vue.mixin({
    beforeCreate() {
        if (this.$options.watch) {
            Object.entries(this.$options.watch).forEach(([watcherKey, func]) => {
                // @ts-ignore
                this.$options.watch[watcherKey] = new Proxy(func, {
                    apply(target, thisArg) {
                        // @ts-ignore
                        console.log(`Called watcher ${target.name} on component ${thisArg.$options.name}`);
                    },
                });
            });
        }
    },
});

new Vue({
    router,
    render: h => h(App),
    store
}).$mount('#app')
