


























































import { Component,Mixins } from 'vue-property-decorator';
import {API} from '@/services/api-provider';
import Utils from "@/mixins/utils";
import ReadOnlyField from "@/components/ReadOnlyField.vue";
import HouseholdMemberDetailedLink from "@/components/HouseholdMemberDetailedLink.vue";
import {FrontendAddressLocationWithHouseholds} from "@/models/frontend-address-location-with-households";
import SimpleMap from "@/components/SimpleMap.vue";
import EditableColumnOfFields, {createField, Field} from "@/components/EditableColumnOfFields.vue";

@Component({
    components: {EditableColumnOfFields, ReadOnlyField, HouseholdMemberDetailedLink, SimpleMap}
})
export default class Address extends Mixins(Utils) {
    
  private searching: boolean = false;
  
  private addressLocations: FrontendAddressLocationWithHouseholds[] = [];
  
  private badSearch: boolean = false;
  
  private errorMessage: string = "";
  
  private query: any = {
      streetAddress: "",
      zipCode: "",
      city: ""
  }
  
  mounted() {
      
      const queryStreetAddress = this.$route.query.streetAddress;
      const queryZipCode = this.$route.query.zip;
      const queryCity = this.$route.query.city;
      if(queryStreetAddress || queryZipCode || queryCity){
          this.query.streetAddress=queryStreetAddress;
          this.query.zipCode=queryZipCode;
          this.query.city=queryCity;
          this.search();
      }
  }
  
  beforeCreate() {
      this.$store.dispatch("setCurrentState","ADDRESS-SEARCH")    
  }
  
  search() : Promise<void> {
      if(this.searching) {
          return new Promise<void>(()=> console.log("already searching"));
      }
      return new Promise<void>(resolve => {
          this.badSearch = false;
          this.searching = true;
          API.searchAddress(this.query).then((data: FrontendAddressLocationWithHouseholds[]) => {
              this.addressLocations =data;
          }).catch((resp: any) => {
              this.errorMessage = this.extractErrorMessage(resp);
              console.log(this.errorMessage);
              this.badSearch = true;
              this.searching = false;
          }).finally(() => {
              this.searching = false;
              resolve();
          });
     });
  }

  addressFields(a: FrontendAddressLocationWithHouseholds): Field[] {
      return [
          createField("Gatuadress", a.addressLocation.streetAddress),
          createField("Gatunamn", a.addressLocation.streetName),
          createField("Gatunummer", a.addressLocation.streetNumber),
          createField("Littera", a.addressLocation.entrance),
          createField("Postnummer", a.addressLocation.zip),
          createField("Postort", a.addressLocation.city),
          createField("Ägartyp", a.addressLocation.propertyOwnerType),
          createField("X-koordinat", a.addressLocation.xCoord),
          createField("Y-koordinat", a.addressLocation.yCoord),
          createField("Latitud", a.addressLocation.latitude),
          createField("Longitud", a.addressLocation.longitude),
          createField("Adresskällor", a.addressLocation.sources),

      ]
  }
}
