














import {Component, Emit, Prop} from 'vue-property-decorator';
import Vue from "vue";
import TextWithButton from "@/components/TextWithButton.vue";

/**
 * Renders a row of string values that can wrap to cover multiple rows and thus fit as many elements as needed. New
 * elements can be inserted via a free text or dropdown, or deleted by clicking on an individual element's cross.
 */
@Component({components: {TextWithButton}})
export default class EditableWrapRow extends Vue{
    @Prop()
    value: string[];

    @Prop({required: false})
    options: string[];

    @Prop()
    editable: boolean;

    removeField(idx: number) {
        this.value.splice(idx,1);
        this.input();
    }

    addField(str: string) {
        if (str !== "")
          this.value.push(str);
          this.input();
    }

    get crossIcon() {
        return require('@/assets/cross.png')
    }

    @Emit()
    input(): string[] {
        return this.value;
    }

}
