import Vue from 'vue'
import Router from 'vue-router'
import Home from "@/views/Home.vue";
import Address from "@/views/Address.vue";
import Individual from "@/views/Individual.vue";
import Household from "@/views/Household.vue";
import Company from "@/views/Company.vue";
import Phone from "@/views/Phone.vue";
import Admin from "@/views/Admin.vue";
import Login from "@/views/Login.vue";
import CompanyAuthorisedSignatory from "@/views/CompanyAuthorisedSignatory.vue";
import PEPUpload from "@/views/PEPUpload.vue";
Vue.use(Router);

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: "/",
            name: "home",
            components: {
                default: Home,
                
            }
        },
        {
            path: "/address",
            name: "address",
            components: {
                default: Address
                
            }
        },
        {
            path: "/individual",
            name: "individual",
            components: {
                default: Individual
            },
            props: true,
        },
        {
            path: "/phone",
            name: "phone",
            components: {
                default: Phone
                
            }
        },
        {
            path: "/household",
            name: "household",
            components: {
                default: Household
                
            }
        },
        {
            path: "/company/:searchType?/:orgNo?",
            name: "company",
            components: {
                default: Company
            }
        },
        {
            path: "/company-authorised-signatory",
            name: "company-authorised-signatory",
            components: {
                default: CompanyAuthorisedSignatory
            }
        },
        {
            path: "/pep-upload",
            name: "pep-upload",
            components: {
                default: PEPUpload
            }
        },
        {
            path: "/admin",
            name: "admin",
            components: {
                default: Admin
                
            }
        },
        {
            path: "/login",
            name: "login",
            components: {
                default: Login
                
            }
        },
        {
            path: "/logout",
            name: "logout",
            components: {
                default: Login
                
            }
        },
        {
            path: "/*",
            name: "missing",
            redirect: { name: "home" }
        }
    ]
 })
