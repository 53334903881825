import { HTTP } from "@/services/http";
import jwt_decode from 'jwt-decode'
import STORE from "@/store/store";

export class Auth {

    public async login(auth: any): Promise<any> {


        try {
            await HTTP.post<any>("/auth/authenticate", auth).then((resp: any) => {
                const tmpToken = resp.token;
                STORE.dispatch("setToken", tmpToken);
            });
            return this.whoami();
        } catch (error) {
            await STORE.dispatch("setUser", null);
            return (Promise.reject(error));
        }
    }
    public logout(): Promise<void> {
        return STORE.dispatch("clearAuth");
    }

    public async whoami(): Promise<any> {
        await HTTP.get<any>("/rest/user/whoami").then((resp: any) => {
            STORE.dispatch("setUser", resp);
        });
        if (STORE.state.user) {
            return Promise.resolve();
        }
        return Promise.reject();
    }

    public isLoggedIn() {
        if (!STORE.state.token || STORE.state.token === "") {
            return false;
        }
        const token: string = STORE.state.token;
        const decoded: any = jwt_decode(token);
        if (decoded) {
            const exp: number = decoded.exp;
            if (exp < Date.now() / 1000) {
                console.log("jwt expired");
                return false;
            }
            return true;
        }
        return false;
    }
}

export default new Auth();