







import Vue from 'vue';
import {Component,Prop} from 'vue-property-decorator';

@Component
export default class ReadOnlyField extends Vue {
    @Prop() 
    label: string;
}
