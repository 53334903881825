import Vue from "vue";
import Vuex from 'vuex';

Vue.use(Vuex);
export default new Vuex.Store({
    state: {
        user: JSON.parse(localStorage.getItem("user") || "null"),
        token: localStorage.getItem('token'),
        currentState: "",
    },
    mutations: {
        SET_USER(state, user) {
            state.user = user;
        },
        SET_TOKEN: (state, token) => {
            state.token = token;
        },
        SET_CURRENT_STATE: (state, currentState) => {
            state.currentState = currentState;
        }
    },
    actions: {
        setToken: ({ commit, state }, newValue) => {
            commit('SET_TOKEN', newValue);
            localStorage.setItem("token",newValue);
            return state.token;
        },
        setUser: ({ commit, state }, newValue) => {
            commit('SET_USER', newValue);
            localStorage.setItem("user",JSON.stringify(newValue));
            return state.user;
        },
        clearAuth: ({commit, state}) => {
            commit('SET_USER', null);
            commit('SET_TOKEN', "");
            localStorage.setItem("token","");
            localStorage.setItem("user","null");
        },
        setCurrentState: ({commit, state}, newValue) => {
            commit('SET_CURRENT_STATE', newValue);
            return state.currentState;
        }
    },
    getters: {
        getUser (state) {
            return state.user;
        }
    },
});