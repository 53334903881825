/**
 * A date object.
 */
export type date = {
    year: string,
    month: string,
    date: string,
    hours: string,
    minutes: string,
    seconds: string
}

/**
 * Tries to parse the input to a date object. If not possible then 0 is returned for all fields.
 * @param input
 */
function parseDate(input: string): date {
    // We have 2 formats to handle, we expect these to be the only ones:
    // 1. Date: yyyy-MM-dd
    // 2. Timestamp: 2016-04-27T11:45:03.623

    // By default we will parse to 0000-00-00 00:00:00
    if (!input || input.length < 10) {
        // If we can't even parse a date then we just return default values
        return {
            year: "0000",
            month: "00",
            date: "00",
            hours: "00",
            minutes: "00",
            seconds: "00"
        }
    }
    // Parse the date
    const year = input.substr(0, 4);
    const month = input.substr(5, 2);
    const date = input.substr(8, 2);
    // Default values
    let hours = "00";
    let minutes = "00";
    let seconds = "00";
    if (input.length === 23) {
        hours = input.substr(11, 2);
        minutes = input.substr(14, 2);
        seconds = input.substr(17, 2);
    }
    return {
        year,
        month,
        date,
        hours,
        minutes,
        seconds
    };
}

/**
 * Tries to parse the input into YYYY-MM-DD HH:MM. Defaults to 0000-00-00 00:00.
 *
 * Before this was implemented as a filter in the Angular application but we avoid using Vue filters here because
 * they will be deprecated in Vue 3 if we ever migrate. Use Computed Property instead.
 */
export function toPrettyTimeStamp (input: string): string {
    const date = parseDate(input);
    return date.year + '-' + date.month + '-' + date.date + ' ' + date.hours + ':' + date.minutes;
}
