/**
 * This class contains various static global methods.
 */
export class Global {
    /**
     * The global build hash that is unique to this build.
     */
    public static BUILD_HASH = process.env.VUE_APP_BUILD_HASH;

    /**
     * The base url without trailing "/".
     */
    public static BASE_URL: string = process.env.BASE_URL.replace(/\/$/, "");

    /**
     * Prepends the application base url to the given url. The same behavior
     * applies to both relative and absolute urls. This method should always be
     * used when using urls to within the application that does NOT pass via
     * the Http class.
     */
    public static url(url: string): string {
        if (url.length === 0 || url.charAt(0) !== "/") {
            url = "/" + url;
        }
        return Global.BASE_URL + url;
    }
}