



























































import {Component, Vue} from 'vue-property-decorator';
import {HTTP} from "@/services/http";
import {PEPUploadResponse} from "@/models/pep-upload-response";

/**
 * This component handles the page where a user can upload a PEP file and see if
 * it contains errors, and also choose if it should be imported to the database.
 */
@Component
export default class PEPUpload extends Vue {
    uploadInProgress: boolean = false;

    message: string | null = "";

    uploadResponse: PEPUploadResponse | null = null;


    beforeCreate() {
        this.$store.dispatch("setCurrentState", "PEP-UPLOAD");
    }

    handleFileUploadFromInput(event: Event) {
        let files = (event.target as HTMLInputElement).files;
        this.uploadFile(files, event);
    }

    uploadFile(files: any, htmlInputElementEvent: Event) {
        this.message = "";
        this.uploadResponse = null;
        if (files && files.length === 1) {
            this.uploadInProgress = true;
            let formData: FormData = new FormData();
            formData.append("uploadedFile", files[0]);
            HTTP.post<PEPUploadResponse>("/rest/pep/upload", formData).then((response: PEPUploadResponse) => {
                this.uploadResponse = response;
                this.message = response.message;
            }).catch((response: any) => {
                this.message= "Något gick fel med uppladdningen.";
                console.error(response);
            }).finally(() => {
                // Clear the value of the <input> element
                (htmlInputElementEvent.target as HTMLInputElement).value = "";
                this.uploadInProgress = false;
            });
        }
    }

    confirmFile(): void {
        this.message = "";
        HTTP.post<PEPUploadResponse>("/rest/pep/confirm/" + this.uploadResponse?.fileId).then(() => {
            this.message = "Filen är nu uppladdad till systemet och kommer importeras vid nästa nattkörning."
        }).catch((response: any) => {
            this.message = "Något gick fel när filen skulle bekräftas.";
            console.error(response);
        }).finally(() => {
            this.uploadResponse = null;
        });
    }

    cancelFile(): void {
        this.message = "";
        HTTP.post<PEPUploadResponse>("/rest/pep/cancel/" + this.uploadResponse?.fileId).then(() => {
            this.message = "Filen är nu borttagen."
        }).catch((response: any) => {
            this.message = "Något gick fel när filen skulle tas bort. Den kommer dock inte importeras av systemet då den ej blivit bekräftad.";
            console.error(response);
        }).finally(() => {
            this.uploadResponse = null;
        });
    }
}
