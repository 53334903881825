/**
 * See java class with same name.
 */
export type ExportFilter = MockExportFilter & {
    id: number;
}

export function defaultExportFilterFile(): ExportFilterFile {
    return {
        exportType: ExportType.BASIC,
        rowFilters: [],
        fields: [],
        filePrefix: "",
        sortBy: ""
    }
}

/**
 * See java class with same name.
 */
export type CreateExportFilterRequest = MockExportFilter;

export type MockExportFilter = {
    customer: string;
    files: ExportFilterFile[];
}

export function mockExportFilter(): MockExportFilter {
    return {
        customer: "",
        files: []
    }
}

export type ExportFilterFile = {
    exportType: ExportType;
    filePrefix: string;
    sortBy: string;
    fields: ExportFilterField[];
    rowFilters: ExportFilterRowFilter[];
};

export type ExportFilterField = {
    field: string;
};

export type ExportFilterRowFilter = {
    filterField: string;
    filterOnEquals: string;
    fieldsToKeep: ExportFilterField[];
};

export function defaultExportRowFilter(): ExportFilterRowFilter {
    return {
        fieldsToKeep: [],
        filterOnEquals: "",
        filterField: ""
    }
}

export enum ExportType {
    BASIC = "BASIC",
    INDIVIDUAL = "INDIVIDUAL",
    HOUSEHOLD = "HOUSEHOLD",
    ADDRESS_LOCATION = "ADDRESS_LOCATION",
    INDIVIDUAL_LOCATION_RELATION = "INDIVIDUAL_LOCATION_RELATION",
    COMPANY_LOCATION_RELATION = "COMPANY_LOCATION_RELATION",
    SUBSCRIPTION = "SUBSCRIPTION",
    COMPANY = "COMPANY",
    COMPANY_GROUP = "COMPANY_GROUP",
    ANNUAL_REPORT_ORGNO = "ANNUAL_REPORT_ORGNO",
    EVENT = "EVENT",
    HISTORICAL_COMPANY_NAMES = "HISTORICAL_COMPANY_NAMES",
    ADDRESS_LOCATION_RELATION = "ADDRESS_LOCATION_RELATION",
    VEHICLE = "VEHICLE",
    VEHICLE_OWNER = "VEHICLE_OWNER",
    REAL_ESTATE_LOCATION_RELATION = "REAL_ESTATE_LOCATION_RELATION",
    UNKNOWN_VEHICLE_OWNER = "UNKNOWN_VEHICLE_OWNER",
}

/**
 * Returns an array of all ExportType options. Order is not guaranteed.
 */
export function ExportTypeAsList(): string[] {
    const types = [];
    for (const type in ExportType) {
        types.push(type)
    }
    return types;
}
